import React from "react";
import { Row } from "shards-react";
import { isEmpty, isUndefined } from "underscore";
import "./userlist.css";
import { useHistory } from "react-router-dom";
import TableComponent from "../../../../views/components/TableComponent";
import DetailCandidateDropDownPart from "../../../../views/DetailCandidate/DetailCandidateDropDownPart";

const titleFormatter = (column, colIndex) => {
  return (
    <>
      {column.text}
      <i class="material-icons md-24 " style={{ color: "rgb(209, 209, 207)" }}>
        unfold_more
      </i>
    </>
  );
};

export default function UserList({ candidate_list }) {
  const history = useHistory();
  const columns = [
    {
      col: "col1",
      title: "İşlem Kodu",
      headerFormatter: titleFormatter
    },
    {
      col: "col2",
      title: "Müşteri İsmi",
      headerFormatter: titleFormatter
    },
    {
      col: "col3",
      title: "Başvuru Türü",
      headerFormatter: titleFormatter
    },
    {
      col: "col4",
      title: "Başvuru Tarihi",
      headerFormatter: titleFormatter
    },
    {
      col: "col5",
      title: "Durumu",
      headerFormatter: titleFormatter
    },
    {
      col: "col6",
      title: "Kişi Detay",
      formatter: (cellContent, row) => {
        return (
          <span
            style={{ color: "#7f63f4", lineHeight: 1.5 }}
            onClick={() => {
              history.push(
                `/dashboard/candidate/detailCandidateInfo/${row["col1"]}`
              );
            }}
          >
            Ek Bilgiler
          </span>
        );
      }
    }
  ];

  const rows =
    !isEmpty(candidate_list) &&
    candidate_list.map(item => {
      const container = {};
      container["col1"] = item.id;
      container["col2"] = item.name.toUpperCase();
      container["col3"] = item.application_type;
      container["col4"] = item.application_date;
      container["col5"] = item.status;
      return container;
    });

  console.log(candidate_list);

  return (
    <DetailCandidateDropDownPart control={false} title={"İş Listesi"}>
      {isEmpty(candidate_list) ? (
        <span>Reddedilen veya onaylanan bir kullanıcı bulunamadı.</span>
      ) : (
        <>
          <TableComponent col={columns} row={rows} />
        </>
      )}
    </DetailCandidateDropDownPart>
  );
}
