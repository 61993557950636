import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "shards-react";

function DetailCandidateDropDownPart(props) {
  const [show, setshow] = useState(false);
  return (
    <Card className={props.className}>
      <CardHeader className="border-bottom">
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          onClick={() => setshow(!show)}
        >
          <h6 className="m-0" style={{ fontFamily: "Montserrat" }}>
            {props.title}
          </h6>
          {props.control && (
            <>
              {show ? (
                <i class="material-icons md-24 ">expand_more</i>
              ) : (
                <i class="material-icons md-24 ">expand_less</i>
              )}
            </>
          )}
        </div>
      </CardHeader>
      {props.control && <>{show && <CardBody> {props.children} </CardBody>}</>}
      {!props.control && <><CardBody> {props.children} </CardBody></>}
    </Card>
  );
}

export default DetailCandidateDropDownPart;
