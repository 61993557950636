import React, { useEffect, useState } from "react";
import ComponentsContainer from "../../ComponentsContainer";
import DetailCandidateDropDownPart from "../../DetailCandidate/DetailCandidateDropDownPart";
import ApiService from "../../../services/ApiService";
import { Col, Row } from "react-bootstrap";

function AddOperator(props) {
  const [operatorName, setOperatorName] = useState("");
  const [operatorSurname, setOperatorSurname] = useState("");
  const [operatorPhone, setOperatorPhone] = useState("");

  const onChangeOperatorName = event => setOperatorName(event.target.value);
  const onChangeOperatorSurname = event =>
    setOperatorSurname(event.target.value);
  const onChangeOperatorPhone = event => setOperatorPhone(event.target.value);

  const onSubmit = () => {
    const token = localStorage.getItem("token");
    ApiService.post(
      "/see_and_sign/banking/admin/create_operator",
      {
        name: operatorName,
        surname: operatorSurname,
        phoneNumber: operatorPhone
      },
      token
    ).then(({ data }) => {
      console.log(data);
    });
  };

  return (
    <ComponentsContainer title={"Operatör Ekleme Ekranı"}>
      <DetailCandidateDropDownPart control={false} title={"Yeni Operatör"}>
        <Row style={{justifyContent:'center', alignItems:'center', padding:'2%'}} >
          <Col md={5} >
            <form>
              <div class="form-group">
                <label style={{ fontWeight: "bold" }}>Operatör adı</label>
                <input
                  type="name"
                  class="form-control"
                  placeholder="Ad"
                  onChange={onChangeOperatorName}
                />
              </div>
              <div class="form-group">
                <label style={{ fontWeight: "bold" }}>Operatör Soyadı</label>
                <input
                  type="name"
                  class="form-control"
                  placeholder="Soyad"
                  onChange={onChangeOperatorSurname}
                />
              </div>
              <div class="form-group">
                <label style={{ fontWeight: "bold" }}>
                  Operatör Telefon Numarası
                </label>
                <input
                  type="name"
                  class="form-control"
                  placeholder="Telefon Numarası"
                  onChange={onChangeOperatorPhone}
                />
              </div>
              <button
                type="button"
                class="btn btn-primary"
                style={{
                  width: "100%",
                  backgroundColor: "#7668ac",
                  borderRadius: 24
                }}
                onClick={onSubmit}
              >
                Ekle
              </button>
            </form>
          </Col>
        </Row>
      </DetailCandidateDropDownPart>
    </ComponentsContainer>
  );
}

export default AddOperator;
