import React from 'react';
import Login from './Login';
import styleConf from './styleConf';
import background from '../../../images/Icon/background.svg';
import LoginSlide from './LoginSlide';
import { Container, Row, Col } from "shards-react";

function LoginScreen(props) {
    return (
        <Row>
        <Col lg="4" className="mb-4" >
            <Login/>
        </Col>
        <Col lg="8" className="mb-4"  style={{height:styleConf.screenHeight,
         backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat',display: 'flex',
         flexDirection: 'column', 
         justifyContent: 'center',
         alignItems:'center'}}>  
                <LoginSlide/>
        </Col>
        </Row>
    )
}

export default LoginScreen; 
