import React, { useState, useEffect } from "react";
import ApiService from "../../../services/ApiService";
import "./Login.css";
import ErrorModal from "./ErrorModal";
import logo from "../../../images/Icon/logo-dark.svg";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [show, setshow] = useState(false);
  const [type, setType] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onSubmitAdmin = e => {
    ApiService.post("/see_and_sign/banking/admin/login", {
      phoneNumber,
      password
    })
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          localStorage.setItem("admin", true);
          localStorage.setItem("token", data.token);
          localStorage.setItem("userName", data.name);
          localStorage.setItem("userSurname", data.surname);
          history.push("/dashboard/admin/home");
        } else {
          setshow(true);
        }
      })
      .catch(e => setType(1));

    e.preventDefault();
  };

  const onSubmit = e => {
    console.log(password);
    ApiService.post("/see_and_sign/banking/operator/login", {
      phoneNumber,
      password
    })
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          localStorage.setItem("admin", false);
          localStorage.setItem("token", data.token);
          localStorage.setItem("userName", data.name);
          localStorage.setItem("userSurname", data.surname);

          history.push("/dashboard/home");
        } else {
          setshow(true);
          setErrorMessage(data.error);
        }
      })
      .catch(e => setType(1));

    e.preventDefault();
  };

  const onChangePhoneNumber = event => setPhoneNumber(event.target.value);
  const onChangePassword = event => setPassword(event.target.value);

  return (
    <>
      {type === 1 ? (
        <ErrorModal
          show={show}
          handleClose={() => setshow(false)}
          title={"Bağlantı Hatası"}
          description={"Sunucuya Bağlanırken Bir Hata İle Karşılaşıldı"}
        />
      ) : (
        <ErrorModal
          show={show}
          handleClose={() => setshow(false)}
          title={"Kullanıcı Giriş Hatası"}
          description={errorMessage}
        />
      )}
      <div style={{ marginBottom: "15%", marginTop: "5%", marginLeft: "5%" }}>
        <img src={`${logo}`} width="110px" height="130px" alt="logo" />
      </div>
      <div className="wrapper">
        <div id="formContent">
          <div className="fadeIn ">
            <p className="loginTitle">Hoş geldin!</p>
          </div>
          <form className="fadeIn">
            <p className="lab">
              Telefon Numarası, E-posta veya Kullanıcı ID'si
            </p>
            <input
              type="text"
              id="login"
              className=""
              name="login"
              placeholder="Telefon Numarası"
              onChange={onChangePhoneNumber}
            />
            <p className="lab">Parola</p>
            <input
              type={"password"}
              id="password"
              className=""
              name="login"
              placeholder="Parola"
              onChange={onChangePassword}
            />
            <Row>
              <Col>
                <input type="submit" onClick={onSubmit} value="Giriş" />
              </Col>
              <Col>
                <input
                  type="submit"
                  onClick={onSubmitAdmin}
                  value="Admin Giriş"
                />
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}
