import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import ComponentsContainer from "../ComponentsContainer";
import DetailCandidateDropDownPart from "../DetailCandidate/DetailCandidateDropDownPart";
import { useHistory } from "react-router-dom";
import TableComponent from "../components/TableComponent";

const titleFormatter = (column, colIndex) => {
  return (
    <>
      {column.text}
      <i class="material-icons md-24 " style={{ color: "rgb(209, 209, 207)" }}>
        unfold_more
      </i>
    </>
  );
};

const AdminHome = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchdata()
  },[]);

  const fetchdata = () => {
    console.log('aaaaa');
    const token = localStorage.getItem("token");
     ApiService.get(
      "/see_and_sign/banking/admin/get_all_candidates",
      token
    ).then(({ data }) => {
      console.log(data.candidate_list);
      if (data.success) {
        setLoading(false);
        setList(data.candidate_list);
      }
    });
  };

  const history = useHistory();
  const columns = [
    {
      col: "col1",
      title: "İşlem Kodu",
      headerFormatter: titleFormatter
    },
    {
      col: "col2",
      title: "Müşteri İsmi",
      headerFormatter: titleFormatter
    },
    {
      col: "col3",
      title: "Başvuru Türü",
      headerFormatter: titleFormatter
    },
    {
      col: "col4",
      title: "Başvuru Tarihi",
      headerFormatter: titleFormatter
    },
    {
      col: "col5",
      title: "Durumu",
      headerFormatter: titleFormatter
    },
    {
      col: "col6",
      title: "Kişi Detay",
      formatter: (cellContent, row) => {
        return (
          <span
            style={{ color: "#7f63f4", lineHeight: 1.5 }}
            onClick={() => {
              history.push(`/dashboard/admin/candidateDetail/${row["col1"]}`);
            }}
          >
            Ek Bilgiler
          </span>
        );
      }
    }
  ];

  const rows = list.map(item => {
    const container = {};
    container["col1"] = item.id;
    container["col2"] = item.name.toUpperCase();
    container["col3"] = item.application_type;
    container["col4"] = item.application_date;
    container["col5"] = item.status;
    return container;
  });

  return (
    <ComponentsContainer title={"Operatör İşlemleri "}>
      <DetailCandidateDropDownPart
        control={false}
        title={"Operatör İşlem Listesi"}
      >
        {loading ? (
          <div class="spinner-border text-success " role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <TableComponent col={columns} row={rows} />
          </>
        )}
      </DetailCandidateDropDownPart>
    </ComponentsContainer>
  );
};

export default AdminHome;
