export default function() {
  return [
    {
      title: "Anasayfa",
      to: "/dashboard/home",
      htmlBefore: '<i class="material-icons">public</i>',
      htmlAfter: "",
    },
  ];
}
