import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../DetailCandidate/info.css";

function TableComponent(props) {
  const { SearchBar } = Search;

  const rowStyle = (row, rowIndex) => {
    if (rowIndex % 2 === 0) {
      return {
        backgroundColor: "rgba(209, 209, 207,0.2)"
      };
    } else {
      return {
        backgroundColor: "white"
      };
    }
  };

  const cols = props.col.map(item => {
    const container = {};
    container["dataField"] = item.col;
    container["text"] = item.title;
    container["sort"] = true;
    container["formatter"] = item.formatter;
    container["headerFormatter"] = item.headerFormatter;
    return container;
  });


  return (
    <ToolkitProvider keyField="id" data={props.row} columns={cols} search>
      {props => (
        <div>
         <div style={{textAlign:'center'}}>  
          <p className='spanTitle' >
              <span style={{paddingRight:'2%'}}> Filtrelemek İstediğiniz Değeri Giriniz</span>  
          <SearchBar          
           { ...props.searchProps }
          className="custome-search-field"
          style={ { color: 'black', borderRadius:15,} }
          placeholder="Değer Giriniz!"/>
          <hr />
          </p>
          </div> 
          <BootstrapTable {...props.baseProps} rowStyle={rowStyle} pagination={paginationFactory()} filter={filterFactory()}/>
        </div>
      )}
    </ToolkitProvider>
  );
}

export default TableComponent;

/**
 *               {props.col && (
              <BootstrapTable
                keyField="id"
                data={props.row}
                columns={cols}
                pagination={paginationFactory()}
                rowStyle={rowStyle}
                filter={filterFactory()}
              />
              )}
 * 
 */
