import React, { useRef } from "react";
import { Row, Col } from "shards-react";
import ControlComponent from "./ControlComponent";
import JutsuComponent from "./JutsuComponent";

import "./videocall.css";
import { useParams } from "react-router";
import ComponentsContainer from "../../ComponentsContainer";
import DetailCandidateDropDownPart from "../../DetailCandidate/DetailCandidateDropDownPart";
import { ToastContainer, toast } from "react-toastify";

function VideoCall() {
  let { roomName } = useParams();

  const jitsuRef = useRef();

  const shotFrontID = () => {
    jitsuRef.current.screenShotJitsiFront();
  };
  const shotBackID = () => {
    jitsuRef.current.screenShotJitsiBack();
  };

  const userName = localStorage.getItem("userName");
  const userSurname = localStorage.getItem("userSurname");
  
  return (
    <ComponentsContainer
      title={"Görüntülü Görüşme"}
      subTitle={"call2in"}
      subTitleDescription={"Talepler - Görüntülü Görüşme"}
    >
      <Row>
        <Col lg="8" className="mb-4">
          <DetailCandidateDropDownPart
            control={false}
            title={"Konuşma Ekranı"}
            className={"mb-4"}
          >
            <JutsuComponent
              roomName={roomName}
              name={userName + userSurname}
              ref={jitsuRef}
            />
          </DetailCandidateDropDownPart>
        </Col>
        <Col lg="4" className="mb-4">
          <ControlComponent idFrontShot={shotFrontID} idBackShot={shotBackID} />
        </Col>
      </Row>

    </ComponentsContainer>
  );
}

export default VideoCall;
