import React, { Component } from 'react';
import Carousel from 'react-elastic-carousel';

export default class LoginSlide extends Component {
  state = {
    items: [
      {id: 1, title: 'Kimliğinizi özüne döndürüyor, teknolojiyle güçlendiriyoruz.',
       body: 'Call2in, gerçek müşterileri onaylamak, uzaktan kimlik doğrulama yaparak hizmetlere erişim izni vermek ve kimlik dolandırıcılık girişimlerini caydırmak için sunulan Doğrulanabilir Dijital Biyometrik Kimlik(DDBK) çözümüdür. '},
      {id: 2, title: 'Önce güvenlik,her zaman.',
       body: 'Kullanıcılarımıza ve veri güvenliğine verdiğimiz önemden dolayı sistem üçüncü taraflarla meta veri paylaşımına izin vermez, alınan veriler şifrelenerek blok zincir üzerinde saklanır. En yüksek güvenlik standartlarına bağlıyız ve en iyi teknoloji ortaklarına sahibiz.'},
      {id: 3, title: 'Call2in tam bağımsız bir uygulama olarak, Türkiye’de geliştirilmiştir.',
      body:'Türkiye’de BDDK ve BTK yönetmelikleri ile uyumluilk ve tek yerli sistemdir.'},
    ]
  }

  render () {
    const { items } = this.state;
    return (
      <Carousel>
        {items.map(item =>
            <div className="slide" key={item.id}>
              <p className="slideTitle">{item.title} </p> 
              <p className="slideBody">{item.body} </p> 
            </div>
        )}
      </Carousel>
    )
    }
}
