export default function() {
  return [
    {
      title: "Admin",
      to: "/dashboard/admin/home",
      htmlBefore: '<i class="material-icons">public</i>',
      htmlAfter: "",
    },
    {
      title: "Operator Ekleme",
      to: "/dashboard/admin/addOperator",
      htmlBefore: '<i class="material-icons">add_circle</i>',
      htmlAfter: "",
    },
    {
      title: "Operator Listesi",
      to: "/dashboard/admin/operatorList",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
    },
  ];
}
