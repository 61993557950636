import React from "react";
import FlatList from "flatlist-react";
import { Row, Col } from "react-bootstrap";
import "./info.css";

function DetailList({ ...props }) {
  const renderList = (listItems, idx) => {
    return (
      <>
        <Row key={idx} style={props.style}>
          <Col  className="listTitle" style={{ color: listItems.color1 }}>
            {listItems.listTitle}
          </Col>
          <Col  className="listBody">
            {listItems.listSeperator &&
            <span style={{ paddingRight: "10%"}}>
              {listItems.listSeperator}
            </span> }
            <span style={{ color: listItems.color2 }}>
              {listItems.listBody}
            </span>
          </Col>
          {listItems.col3 && (
            <Col  className="listBody" style={{ color: listItems.color3 }}>
              {listItems.col3}
            </Col>
          )}
          {listItems.col4 && (
            <Col md={1} className="listBody">
              <i class="material-icons md-24 " style={{ color: listItems.color4 }}>{listItems.col4}</i>
            </Col>
          )}
          {listItems.underline && <hr className="underline" />}
        </Row>
        <br/>
      </>
    );
  };

  return (
    <FlatList
      list={props.listItems}
      renderItem={renderList}
      renderWhenEmpty={() => <div>List is empty!</div>}
    />
  );
}

export default DetailList;
