import React, { useEffect, useImperativeHandle, forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styleConf from "../login/styleConf";
import { importJitsiApi } from "./JitsiApi";
import ApiService from "../../../services/ApiService";

export const JutsuComponent = forwardRef((props, ref) => {
  const oref = React.createRef(null);
  let api;
  const history = useHistory();

  useImperativeHandle(ref, () => ({

    screenShotJitsiFront() {
      console.log('ab');
      api.captureLargeVideoScreenshot().then(data => {
        localStorage.removeItem('frontIDImage');
        localStorage.setItem('frontIDImage',data.dataURL);
      });
    },
    screenShotJitsiBack() {
      console.log('aa');
      api.captureLargeVideoScreenshot().then(data => {
        localStorage.setItem('backIDImage',data.dataURL);
      });
    },
  }));

  const startConference = jitsiApi => {
    const domain = "api.seeandsign.com/";
    const options = {
      roomName: props.roomName,
      parentNode: oref.current
    };
    api = new jitsiApi(domain, options);
    api.addEventListener('videoConferenceJoined', (e) => { 
      //api.executeCommand('startRecording', { mode: 'file',shouldShare: false, });
      const numberOfParticipants = api.getNumberOfParticipants();
      console.log("paticipant ---------------------------***** ",numberOfParticipants);
      if(numberOfParticipants === 0){
        const candidateInfo = JSON.parse(localStorage.getItem("candidateInfo"));
        const token = localStorage.getItem("token");
        ApiService.post(
          "/see_and_sign/banking/operator/reject_application",
          { applicationId: candidateInfo.id },
          token
        ).then(({ data }) => {
          if (data.success) {
            history.push("/dashboard/home");
          }
        });
      } 
    });
  
  };

  useEffect(() => {
    importJitsiApi()
      .then(jitsiApi => {
        startConference(jitsiApi);
      })
      .catch(err => {
        console.error("Jitsi Meet API library not loaded.", err);
      });
  }, []);

  return (
    <>
      <div ref={oref} style={{ height:styleConf.screenHeight*0.7 }} />
    </>
  );
});

export default JutsuComponent;
