import React, { useEffect, useState } from "react";

import {Row, Col } from "react-bootstrap";
import ApiService from "../../services/ApiService";

import SummaryInfo from "./SummaryInfo";
import ApplicationStatus from "./ApplicationStatus";
import ComponentsContainer from "../ComponentsContainer";

export default function DetailCandidate() {
  const [candidateInfo, setCandidateInfo] = useState({});
  const [info, setInfo] = useState({});
  const [form, setForm] = useState({});
  const [roomInfo, setRoomInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    ApiService.post(
      "/see_and_sign/banking/operator/get_candidate",
      {},
      token
    ).then(({ data }) => {
      setLoading(false);
      console.log(data);
      if (data.success) {
        setCandidateInfo(data.candidate.info);
        setInfo(data.candidate.id);
        setForm(data.candidate.form);
        setRoomInfo(data.candidate.video_call);
        setDescription(data.description);

        localStorage.setItem("face", JSON.stringify(data.candidate.face));
        localStorage.setItem(
          "candidateInfo",
          JSON.stringify(data.candidate.info)
        );
        localStorage.setItem("info", JSON.stringify(data.candidate.id));
        localStorage.setItem("form", JSON.stringify(data.candidate.form));

        localStorage.setItem(
          "RoomInfo",
          JSON.stringify(data.candidate.video_call)
        );
      }
    });
  }, []);

  return (
    <ComponentsContainer title={"Doğrulama Raporu"} description={description}>
        <Row>
          <Col xs={12} md={8}>
            <SummaryInfo
              form={form}
              candidateInfo={candidateInfo}
              info={info}
            />
          </Col>

          {/*SAĞ COL */}
          <Col xs={12} md={4}>
            <ApplicationStatus
              status={"Beklemede"}
              show={true}
              loading={loading}
              roomInfo={roomInfo}
              candidateInfo={candidateInfo}
              style={{ backgroundColor: "#f9c723", textAlign: "center" }}
            />
          </Col>
        </Row>
    </ComponentsContainer>
  );
}
