import React, { useEffect, useState } from "react";
import ComponentsContainer from "../../ComponentsContainer";
import DetailCandidateDropDownPart from "../../DetailCandidate/DetailCandidateDropDownPart";
import ApiService from "../../../services/ApiService";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { isEmpty } from "underscore";
import TableComponent from "../../components/TableComponent";
import { useHistory } from "react-router-dom";


function OperatorDetail(props) {
  let { id } = useParams();
  const history = useHistory();

  const [candidateList, setCandidatelist] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    ApiService.get(
      "/see_and_sign/banking/admin/get_candidates_by_operator",
      token,
      {
        operatorId: id
      }
    ).then(({ data }) => {
      setCandidatelist(data.candidate_list);
      console.log(data);
    });

    ApiService.get("/see_and_sign/banking/admin/get_operator_details", token, {
      operatorId: id
    }).then(({ data }) => {
      console.log(data);
      setDetail(data);
    });
  }, []);

  const columns = [
    {
      col: "col1",
      title: "İşlem Kodu"
    },
    {
      col: "col2",
      title: "Müşteri İsmi"
    },
    {
      col: "col3",
      title: "Başvuru Türü"
    },
    {
      col: "col4",
      title: "Başvuru Tarihi"
    },
    {
      col: "col5",
      title: "Durumu"
    },
    {
      col: "col6",
      title: "Kişi Detay",
      formatter: (cellContent, row) => {
        return (
          <span
            style={{ color: "#7f63f4", lineHeight: 1.5 }}
            onClick={() => {
              history.push(`/dashboard/admin/candidateDetail/${row["col1"]}`)
            }}
          >
            Ek Bilgiler
          </span>
        );
      }
    }
  ];

  const rows =
    !isEmpty(candidateList) &&
    candidateList.map(item => {
      const container = {};
      container["col1"] = item.id;
      container["col2"] = item.name.toUpperCase();
      container["col3"] = item.application_type;
      container["col4"] = item.application_date;
      container["col5"] = item.status;
      return container;
    });

  return (
    <ComponentsContainer title={"Operatör Detay Ekranı"}>
      <DetailCandidateDropDownPart control={false} title={"Operatör Detayı"} >
        <Row>
          <Col className="col-lg mb-4">
            <div class="form-group">
              <label class="">Ad</label>
              <div class="">
                <input
                  type="name"
                  class="form-control"
                  placeholder={detail.operatorName}
                />
              </div>
            </div>
          </Col>
          <Col className="col-lg mb-4">
            <div class="form-group">
              <label class="">Soyad</label>
              <div class="">
                <input
                  type="name"
                  class="form-control"
                  placeholder={detail.operatorSurname}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row md={6}>
          <Col className="col-lg mb-2">
            <div class="form-group">
              <label class="">Telefon</label>
              <div class="">
                <input
                  type="name"
                  class="form-control"
                  placeholder={detail.signedUp}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row sm={12} style={{float:'right'}}>
          <Col>
            <Button variant="primary">Operatörü Güncelle</Button>
            <Button variant="danger" style={{marginLeft:10}}>Operatörü Sil</Button>
          </Col>
        </Row>
      </DetailCandidateDropDownPart>
      <DetailCandidateDropDownPart
        control={false}
        title={"Operatör iş listesi"}
        className={"mt-5"}
      >
        {isEmpty(candidateList) && (
          <h4 className="mx-auto">Listelenecek iş bulunamadı</h4>
        )}
        {!isEmpty(rows) && <TableComponent col={columns} row={rows} />}
      </DetailCandidateDropDownPart>
    </ComponentsContainer>
  );
}

export default OperatorDetail;
