import React from "react";
import { Container } from "shards-react";


const LoginScreenLayout = ({ children}) => (
  <Container fluid>
        {children}
  </Container>
);


export default LoginScreenLayout;
