import React from "react";
import { Card, CardBody, CardHeader } from "shards-react";
import './info.css';

function SummaryCards(props) {
  return (
    <Card className={props.className} style={{width:'100%'}}>
      <CardHeader className="border-bottom">
        <h6
          className='cardTitle'
       >
          {props.title}
          <br/>
          <span className='cardSubtitle'>{props.subtitle}</span>
        </h6>
       
      </CardHeader>
      {props.children &&
      <CardBody> {props.children} </CardBody>
      }
    </Card>
  );
}

export default SummaryCards;
