import React from "react";
import { Container, Alert,Row } from "react-bootstrap";

function ComponentsContainer(props) {
  return (
    <>
      <Container fluid className="px-0">
        <Alert
          style={{
            backgroundColor: " rgba(127, 99, 244,0.1)",
            height: 60,
            alignItems: "initial",
            textAlign: "left"
          }}
        >
          <div>
            <p
              style={{
                fontFamily: "Montserrat",
                color: "#7f63f4",
                fontSize: " 16px",
                float: "left"
              }}
            >
              {props.title}

              <br />
              {props.description && (
                <span
                  style={{
                    fontFamily: "Montserrat",
                    backgroundColor: "red",
                    textAlign: "left",
                    padding: "1px 5px",
                    color: "white",
                    fontSize: "12px"
                  }}
                >
                  !!! {props.description} !!!
                </span>
              )}
            </p>

            <p style={{ float: "right" }}>
                <>
                  <a class="logobutton" href="/dashboard/home">
                    {" "}
                    {props.subTitle}{" "}
                  </a>
                  <span className="talep">{props.subTitleDescription}</span>
                </>
            </p>
          </div>
        </Alert>
      </Container>
      <Container
        fluid
        className="main-content-container px-4 pb-4"
        style={{ fontFamily: "Montserrat" }}
      >
        <Row noGutters className="page-header py-4"></Row>
        {props.children}
      </Container>
    </>
  );
}

export default ComponentsContainer;
