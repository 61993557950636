import React, { useEffect, useState } from "react";
import ComponentsContainer from "../../ComponentsContainer";
import DetailCandidateDropDownPart from "../../DetailCandidate/DetailCandidateDropDownPart";
import ApiService from "../../../services/ApiService";
import TableComponent from "../../components/TableComponent";

import { useHistory } from "react-router-dom";

const titleFormatter=(column, colIndex)=> {
  return (
    <>
      {column.text}
      <i class="material-icons md-24 " style={{ color: "rgb(209, 209, 207)" }}>
        unfold_more
      </i>
    </>
  );
}

function OperatorList(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    
    ApiService.get(
      "/see_and_sign/banking/admin/get_all_operator_list",
      token
    ).then(({ data }) => {
      setLoading(false);
      setList(data);
      console.log(data);
    });
  }, []);

  const columns = [
    {
      col: "col1",
      title: "Operatör Id",
     headerFormatter: titleFormatter
    },
    {
      col: "col2",
      title: "Operatör İsmi",
      headerFormatter: titleFormatter
    },
    {
      col: "col3",
      title: "Operatör Soyismi",
      headerFormatter: titleFormatter
    },
    {
      col: "col4",
      title: "Operatör Tel.No",
      headerFormatter: titleFormatter
    },
    {
      col: "col5",
      title: "Detay",
      formatter: (cellContent, row) => {
        return (
          <button
            type="button"
            class="btn btn-success"
            style={{
              backgroundColor: "rgb(127, 99, 244)",
              borderColor: "rgb(127, 99, 244)"
            }}
            onClick={() => {
              history.push(`/dashboard/admin/operatorDetail/${row["col1"]}`);
            }}
          >
            Gör
          </button>
        );
      }
    }
  ];

  const rows = list.map(item => {
    const container = {};
    container["col1"] = item.operatorId;
    container["col2"] = item.operatorName.toUpperCase();
    container["col3"] = item.operatorSurname.toUpperCase();
    container["col4"] = item.signedUp;
    container["col5"] = item.formatter;
    return container;
  });

  console.log(rows);

  return (
    <ComponentsContainer title={"Operatör Listeme Ekranı"}>
      <DetailCandidateDropDownPart control={false} title={"Operatör Listesi"}>
        {loading ? (
          <div class="spinner-border text-success " role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <TableComponent col={columns} row={rows} />
          </>
        )}
      </DetailCandidateDropDownPart>
    </ComponentsContainer>
  );
}

export default OperatorList;
