import React from "react";
import { useHistory } from "react-router-dom";
import {Row, Col, Button} from "react-bootstrap";
import { Card, CardBody, CardHeader } from "shards-react";
import ApiService from "../../services/ApiService";
import DetailList from "./DetailList";

const list2 = [
  {
    listTitle: "Telefon Doğrulama",
    md1: "5",
    listBody: "Onaylandı",
    color2: "#6cc57c",
    col4: "check_circle",
    color4: "#6cc57c"
  },
  {
    listTitle: "KVKK",
    listBody: "Onaylandı",
    color2: "#6cc57c",
    col4: "check_circle",
    color4: "#6cc57c"
  },
  {
    listTitle: "Kimlik Belge Doğrulama",
    listBody: "Onaylandı",
    color2: "#6cc57c",
    col4: "check_circle",
    color4: "#6cc57c"
  },
  {
    listTitle: "Canlılık Kontrolü",
    listBody: "Onaylandı",
    color2: "#6cc57c",
    col4: "check_circle",
    color4: "#6cc57c"
  },
  {
    listTitle: "Jest Onay Kontrolü",
    listBody: "Onaylandı",
    color2: "#6cc57c",
    col4: "check_circle",
    color4: "#6cc57c"
  },
  {
    listTitle: "Yüz Tanıma Kontrolü",
    listBody: "Onaylandı",
    color2: "#6cc57c",
    col4: "check_circle",
    color4: "#6cc57c"
  }
];
function ApplicationStatus(props) {
  const token = localStorage.getItem("token");
  const history = useHistory();

  const declineCandidate = () => {
    ApiService.post(
      "/see_and_sign/banking/operator/reject_application",
      { applicationId: props.candidateInfo.id },
      token
    ).then(({ data }) => {
      if (data.success) {
        history.push("/dashboard/home");
      }
    });
  };

  const startMeetButton = () => {
    history.push(`/dashboard/videocall/${props.roomInfo.room_name}`);
  };
  return (
    <Card className={"m-2"}>
      <CardHeader style={ props.style}>
  <h6 className="waitTitle">{props.status}</h6>
        <p className="waitSubtitle">Başvuru Durumu</p>
      </CardHeader>
      {props.show&&
      <>
      <CardHeader className="border-bottom">
        <h6>Operatör Kararı</h6>
      </CardHeader>     
      <Row className="justify-content-md-center m-2">
        {props.loading ? (
          <div
            class="spinner-border text-success"
            role="status"
            style={{ color: "red" }}
          >
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <Col
              md="auto"
              style={{ paddingBlockEnd: "5%", paddingBlockStart: "5%" }}
            >
              <Button variant="success" onClick={startMeetButton}>
                Görüşmeyi Başlat
              </Button>
            </Col>
            <Col
              md="auto"
              style={{ paddingBlockEnd: "5%", paddingBlockStart: "5%" }}
            >
              <Button variant="danger" onClick={declineCandidate}>
                Reddet
              </Button>
            </Col>
          </>
        )}
      </Row>
      </>
    }
      <CardHeader className="border-bottom">
        <h6>KYC</h6>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <DetailList listItems={list2} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ApplicationStatus;
