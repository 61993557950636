import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Row, Col,} from "react-bootstrap";
import ApiService from "../../services/ApiService";

import { isEqual } from "underscore";

import SummaryInfo from "./SummaryInfo";
import ApplicationStatus from "./ApplicationStatus";
import ComponentsContainer from "../ComponentsContainer";

export default function DetailCandidateInfo() {
  const token = localStorage.getItem("token");

  const [candidateInfo, setCandidateInfo] = useState({});
  const [info, setInfo] = useState({});
  const [form, setForm] = useState({});

  let { id } = useParams();

  useEffect(() => {
    ApiService.post(
      "/see_and_sign/banking/operator/get_candidate_by_id",
      { applicationId: id },
      token
    ).then(({ data }) => {
      setCandidateInfo(data.candidate.info);
      setInfo(data.candidate.id);
      setForm(data.candidate.form);
      console.log(data);
    });
  }, []);

  return (
    <ComponentsContainer title={"Doğrulama Raporu"}>
      <Row>
        <Col xs={12} md={8}>
          <SummaryInfo form={form} candidateInfo={candidateInfo} info={info} />
        </Col>

        {/*SAĞ COL */}
        <Col xs={12} md={4}>
          <ApplicationStatus
            show={false}
            candidateInfo={candidateInfo}
            style={{
              backgroundColor: isEqual(candidateInfo.status, "Reddedildi")
                ? "red"
                : "green",
              textAlign: "center"
            }}
            status={candidateInfo.status}
          />
        </Col>
      </Row>
    </ComponentsContainer>
  );
}
