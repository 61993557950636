import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Form,
  FormInput,
  FormGroup,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import "./videocall.css";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { io } from "socket.io-client";


import moment from "moment";
import ApiService from "../../../services/ApiService";

function ControlComponent(props) {
  const token = localStorage.getItem("token");

  var socket = io.connect();

  socket.on('connect', function() {
    console.log('check 2', socket.connected);
  });

  const [id, setid] = useState(0);
  const history = useHistory();

  const [info, setinfo] = useState({});
  const [form, setfrom] = useState({});
  const [candidateInfo, setCandidateInfo] = useState({});
  const [face, setFace] = useState({});
  const [idFront, setIdFront] = useState();
  const [idBack, setIdBack] = useState();

  useEffect(() => {
    const tempInfo = JSON.parse(localStorage.getItem("info"));
    const tempForm = JSON.parse(localStorage.getItem("form"));
    const tempCandidateInfo = JSON.parse(localStorage.getItem("candidateInfo"));
    const tempFace = JSON.parse(localStorage.getItem("face"));

    setCandidateInfo(tempCandidateInfo);
    setfrom(tempForm);
    setinfo(tempInfo);
    setFace(tempFace);
  }, []);

  const declineCandidate = () => {
    ApiService.post(
      "/see_and_sign/banking/operator/reject_application",
      { applicationId: candidateInfo.id},
      token
    ).then(({ data }) => {
      if (data.success) {
        history.push("/dashboard/home");
      }
    });
  };
  const approveCandidate = () => {
    const idFrontImage = idFront.split(",");
    const idBackImage = idBack.split(",");
    const front = idFrontImage[1];
    const back = idBackImage[1];

    console.log("Front:   ", front);
    console.log("Back:   ", back);
    
    ApiService.post(
      "/see_and_sign/banking/operator/approve_application",
      { applicationId: candidateInfo.id, idFrontSnap: front, idBackSnap: back   },
      token
    ).then(({ data }) => {
      (console.log(data))
      if (data.success) {
        history.push("/dashboard/home");
      }
    });
    
  };

  const FirstComponent = () => {
    const [name, setname] = useState(false);
    const [surname, setsurname] = useState(false);
    const [id, setId] = useState(false);
    const [gender, setgender] = useState(false);
    const [brth, setBrth] = useState(false);
    const [endTime, setendTime] = useState(false);
    const [count, setcount] = useState(0);
    const [control, setcontrol] = useState(false);

    if (
      name === true &&
      surname === true &&
      id === true &&
      gender === true &&
      brth === true &&
      endTime === true &&
      control === false
    ) {
      setcount(1);
      setcontrol(true);
    }
    if (control === true) {
      if (
        name === false ||
        surname === false ||
        id === false ||
        gender === false ||
        brth === false ||
        endTime === false
      ) {
        setcount(0);
        setcontrol(false);
      }
    }

    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-1: Kimlik Belgesi Veri Doğrulama</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Kimlik doğrulama aşamasından gelen bilgiler aşağıda
            gösterilmektedir. Lütfen canlı video görüşmesinde ekranda gördüğünüz
            kimlik üzerindeki bilgileri doğrulanmış verilerle karşılaştırın.
            Eşleşen her bir veri için doğrulama kutucuklarını işaretleyin.
          </p>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3" style={{ fontFamily: "Montserrat" }}>
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0"></th>
                    <th scope="col" className="border-0">
                      Doğrulanmış Veri
                    </th>
                    <th scope="col" className="border-0"></th>
                    <th scope="col" className="border-0">
                      Kontrol
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>İsim</td>
                    <td>{info.name}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={name}
                        onChange={() => {
                          setname(!name);
                        }}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Soyisim</td>
                    <td>{info.surname}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={surname}
                        onChange={() => {
                          setsurname(!surname);
                        }}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>T.C. Kimlik No</td>
                    <td>{info.tc}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={id}
                        onChange={() => {
                          setId(!id);
                        }}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Cinsiyet</td>
                    <td>{info.gender}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={gender}
                        onChange={() => {
                          setgender(!gender);
                        }}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Doğum Tarihi</td>
                    <td>{moment(info.birthDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={brth}
                        onChange={() => {
                          setBrth(!brth);
                        }}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Belge Geçerlilik Tarihi</td>
                    <td>{moment(info.expiry_date).format("DD/MM/YYYY")}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={endTime}
                        onChange={() => {
                          setendTime(!endTime);
                        }}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button className="redB" onClick={declineCandidate}>
              <span className="reddet">Reddet</span>
            </button>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <button
              disabled={count === 1 ? false : true}
              className="onayB"
              onClick={() => {
                setid(1);
              }}
            >
              <span className="onayla">Onayla</span>
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const SecondComponent = () => {
    const [first, setfirst] = useState(false);
    const [second, setsecond] = useState(false);
    const [third, setthird] = useState(false);
    const [fourth, setfourth] = useState(false);
    const [fifth, setfifth] = useState(false);
    const [count, setcount] = useState(0);
    const [control, setcontrol] = useState(false);

    if (
      first === true &&
      second === true &&
      third === true &&
      fourth === true &&
      fifth === true &&
      control === false
    ) {
      setcount(1);
      setcontrol(true);
    }
    if (control === true) {
      if (
        first === false ||
        second === false ||
        third === false ||
        fourth === false ||
        fifth === false
      ) {
        setcount(0);
        setcontrol(false);
      }
    }

    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-2: Kimlik Belgesi Şekil Kontrolü</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Müşteriyi yönlendirerek kimliğin şekli kontrolünü tamamlayın.
            Aşağıdaki aşamalardan herhangi birinde şüpheli bir durum olursa
            Reddet butonuna basın.
          </p>
          <Form>
            <FormGroup>
              <InputGroup seamless className="mb-3">
                <FormInput
                  value="Kimlik kartında yapısal yıpranma yok."
                  required
                  valid={first && true}
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <Checkbox
                      icon={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#71d875",
                            lignSelf: "stretch",
                          }}
                        >
                          <Icon.FiCheck color="white" size={18} />
                        </div>
                      }
                      borderRadius={10}
                      checked={first}
                      onChange={() => setfirst(!first)}
                      borderColor="#71d875"
                      style={{
                        overflow: "hidden",
                        marginTop: "20%",
                        backgroundColor: "white",
                        zindex: 2
                      }}
                      size={20}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup seamless className="mb-3">
                <FormInput
                  value="Hologramı net olarak görüyorum."
                  required
                  valid={second && true}
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <Checkbox
                      icon={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#71d875",
                            lignSelf: "stretch"
                          }}
                        >
                          <Icon.FiCheck color="white" size={18} />
                        </div>
                      }
                      borderRadius={10}
                      checked={second}
                      onChange={() => setsecond(!second)}
                      borderColor="#71d875"
                      style={{
                        overflow: "hidden",
                        marginTop: "20%",
                        backgroundColor: "white",
                        zindex: 2
                      }}
                      size={20}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup seamless className="mb-3">
                <FormInput
                  value="Kimlik üzerindeki fotoğraf ile müşteri eşleşiyor."
                  required
                  valid={third && true}
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <Checkbox
                      icon={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#71d875",
                            lignSelf: "stretch"
                          }}
                        >
                          <Icon.FiCheck color="white" size={18} />
                        </div>
                      }
                      checked={third}
                      borderRadius={10}
                      onChange={() => setthird(!third)}
                      borderColor="#71d875"
                      style={{
                        overflow: "hidden",
                        marginTop: "20%",
                        backgroundColor: "white",
                        zindex: 2
                      }}
                      size={20}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup seamless className="mb-3">
                <FormInput
                  value="Kimliğin ön yüzünde herhangi bir sıkıntı yok."
                  required
                  valid={fourth && true}
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <Checkbox
                      icon={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#71d875",
                            lignSelf: "stretch"
                          }}
                        >
                          <Icon.FiCheck color="white" size={18} />
                        </div>
                      }
                      borderRadius={10}
                      checked={fourth}
                      onChange={() => setfourth(!fourth)}
                      borderColor="#71d875"
                      style={{
                        overflow: "hidden",
                        marginTop: "20%",
                        backgroundColor: "white",
                        zindex: 2
                      }}
                      size={20}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup seamless className="mb-3">
                <FormInput
                  value="Kimliğin arka yüzünde herhangi bir sıkıntı yok."
                  required
                  valid={fifth && true}
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <Checkbox
                      icon={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#71d875",
                            lignSelf: "stretch"
                          }}
                        >
                          <Icon.FiCheck color="white" size={18} />
                        </div>
                      }
                      borderRadius={10}
                      checked={fifth}
                      onChange={() => setfifth(!fifth)}
                      borderColor="#71d875"
                      style={{
                        overflow: "hidden",
                        marginTop: "20%",
                        backgroundColor: "white",
                        zindex: 2
                      }}
                      size={20}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button className="redB" onClick={declineCandidate}>
              <span className="reddet">Reddet</span>
            </button>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <button
              disabled={count === 1 ? false : true}
              className="onayB"
              onClick={() => {
                setid(2);
              }}
            >
              <span className="onayla">Onayla</span>
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const ThirdComponent = () => {
    const [first, setfirst] = useState(false);
    const [third, setthird] = useState(false);
    const [count, setcount] = useState(0);
    const [control, setcontrol] = useState(false);

    if (first === true && third === true && control === false) {
      setcount(1);
      setcontrol(true);
    }
    if (control === true) {
      if (first === false || third === false) {
        setcount(0);
        setcontrol(false);
      }
    }

    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-3: Yüz Biyometrisi Kontrolü</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Kimlik doğrulama aşamasından gelen yüz tanıma ile alakalı bilgiler
            aşağıda gösterilmektedir. Lütfen canlı video görüşmesinde ekranda
            gördüğünüz kişinin yüzünü aşağıda bilgileri doğrulanmış verilerle
            karşılaştırın. Eşleşen her bir veri için doğrulama kutucuklarını
            işaretleyin.
          </p>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3" style={{ fontFamily: "Montserrat" }}>
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0"></th>
                    <th scope="col" className="border-0">
                    </th>
                    <th scope="col" className="border-0"></th>
                    <th scope="col" className="border-0">
                      <span style={{fontSize:11}}>Kontrol</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Yüz Benzerlik Skoru</td>
                    <td>{face.score}</td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={first}
                        onChange={() => setfirst(!first)}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Yüz Fotoğrafı NFC</td>
                    <td>
                      <img
                        src={`data:image/jpeg;base64,${face.id}`}
                        //src={`${logo}`}
                        width="78px"
                        height="87px"
                        alt="faceID"
                      />
                    </td>
                    <td>
                      <i
                        class="material-icons md-24 "
                        style={{ color: "green" }}
                      >
                        check_circle
                      </i>
                    </td>
                    <td>
                      <Checkbox
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#7f63f4",
                              alignSelf: "stretch"
                            }}
                          >
                            <Icon.FiCheck color="white" size={18} />
                          </div>
                        }
                        checked={third}
                        onChange={() => setthird(!third)}
                        borderColor="#7f63f4"
                        style={{ overflow: "hidden", marginTop: "20%" }}
                        labelStyle={{
                          marginLeft: 5,
                          userSelect: "none",
                          color: "#7f63f4"
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button className="redB" onClick={declineCandidate}>
              <span className="reddet">Reddet</span>
            </button>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <button
              disabled={count === 1 ? false : true}
              className="onayB"
              onClick={() => {
                setid(3);
              }}
            >
              <span className="onayla">Onayla</span>
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const FourthComponent = ({ clicked }) => {
    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-4: Kimlik ve Yüz Fotoğrafı Çekimi</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Müşteriden sabit bir şekilde kimliğin önce ön yüzünü, sonra arka
            yüzünü kameraya tutmasını isteyin. Her birinin fotoğrafını çekin.
          </p>
          <Card small className="mb-4">
            <CardBody
              className="p-0 pb-3"
              style={{ fontFamily: "Montserrat", height: "358px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "15%"
                }}
              >
                <p className="photodesc">
                  Kimliğin ön yüzünün fotoğrafını çekin.
                </p>
                <p className="photostep">Adım 2/3</p>
                <button className="photoButton">
                  <span className="buttonIn" onClick={clicked}>
                    Fotoğraf Çek
                  </span>
                </button>
              </div>
            </CardBody>
          </Card>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button
              className="onayB"
              onClick={() => {
                setid(5);
              }}
            >
              <span className="onayla">İleri</span> 
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const CheckFrontIdPhoto = ({}) => {
    let tempIdFront = localStorage.getItem("frontIDImage");
    console.log("ALOOOO " + tempIdFront);
    setIdFront(tempIdFront);
    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-4: Kimlik ve Yüz Fotoğrafı Çekimi</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Müşteriden sabit bir şekilde kimliğin önce ön yüzünü, sonra arka
            yüzünü kameraya tutmasını isteyin. Her birinin fotoğrafını çekin.
          </p>
          <Card small className="mb-4">
            <CardBody
              className="p-0 pb-3"
              style={{ fontFamily: "Montserrat", height: "458px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "5%"
                }}
              >
                <div>
                <img
                  src={`${idFront}`}
                  width="70%"
                  height="290px"
                  alt="faceID"
                />
                </div>
                <div>
                <button className="photoButton">
                  <span className="buttonIn" onClick={()=>setid(3)}>
                    Tekrar Çek
                  </span>
                </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button className="redB" onClick={declineCandidate}>
              <span className="reddet">Reddet</span>
            </button>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <button
              className="onayB"
              onClick={() => {
                setid(4);
              }}
            >
              <span className="onayla">Onayla</span>
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const FifthComponent = ({ shotClickBack }) => {
    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-4: Kimlik ve Yüz Fotoğrafı Çekimi</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Müşteriden sabit bir şekilde kimliğin önce ön yüzünü, sonra arka
            yüzünü kameraya tutmasını isteyin. Her birinin fotoğrafını çekin.
          </p>
          <Card small className="mb-4">
            <CardBody
              className="p-0 pb-3"
              style={{ fontFamily: "Montserrat", height: "358px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "15%"
                }}
              >
                <p className="photodesc">
                  Kimliğin arka yüzünün fotoğrafını çekin.
                </p>
                <p className="photostep">Adım 3/3</p>
                <button className="photoButton" onClick={shotClickBack}>
                  <span className="buttonIn">Fotoğraf Çek</span>
                </button>
              </div>
            </CardBody>
          </Card>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button
              className="onayB"
              onClick={() => {
                setid(6);
              }}
            >
              <span className="onayla">İleri</span>
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const CheckBackIdPhoto = ({}) => {
    let tempIdBack = localStorage.getItem("backIDImage");
    console.log("ALOOOO " + tempIdBack);
    setIdBack(tempIdBack);
    return (
      <>
        <CardHeader className="border-bottom" style={{ padding: "2%" }}>
          <p className="firstTitle">Adım-4: Kimlik ve Yüz Fotoğrafı Çekimi</p>
        </CardHeader>
        <div style={{ padding: "2%" }}>
          <p className="idDesc">
            Müşteriden sabit bir şekilde kimliğin önce ön yüzünü, sonra arka
            yüzünü kameraya tutmasını isteyin. Her birinin fotoğrafını çekin.
          </p>
          <Card small className="mb-4">
            <CardBody
              className="p-0 pb-3"
              style={{ fontFamily: "Montserrat", height: "458px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "5%"
                }}
              >
                <div>
                <img
                  src={`${idBack}`}
                  width="70%"
                  height="290px"
                  alt="faceID"
                />
                </div>
                <div>
                <button className="photoButton">
                  <span className="buttonIn" onClick={()=>setid(4)}>
                    Tekrar Çek
                  </span>
                </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <Row style={{ marginBottom: "15%" }}>
          <Col style={{ textAlign: "center" }}>
            <button className="redB" onClick={declineCandidate}>
              <span className="reddet">Reddet</span>
            </button>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <button className="onayB" onClick={approveCandidate}>
              <span className="onayla">Onayla</span>
            </button>
          </Col>
        </Row>
      </>
    );
  };
  const data = [
    { id: 1, title: "KYC Bilgileri", comp: () => <FirstComponent /> },
    { id: 2, title: "Şekil Kontrolü", comp: () => <SecondComponent /> },
    { id: 3, title: "Yüz Biyometrisi", comp: () => <ThirdComponent /> },
    {
      id: 4,
      title: "Fotoğraf Çekimi",
      comp: () => <FourthComponent clicked={props.idFrontShot} />
    },
    {
      id: 5,
      title: "Fotoğraf Çekimi",
      comp: () => <FifthComponent shotClickBack={props.idBackShot} />
    },
    { id: 6, title: "Fotoğraf Çekimi", comp: () => <CheckFrontIdPhoto/> },
    { id: 7, title: "Fotoğraf Çekimi", comp: () => <CheckBackIdPhoto/> },
  ];

  return (
    <Card small className="mb-4" style={{ fontFamily: "Montserrat" }}>
      <CardHeader
        className="border-bottom"
        style={{
          boxShadow: "3px 19px 20px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#7f63f4",
          justifyContent: "center",
          alignItems: "center",
          padding: "2%"
        }}
      >
        <p className="controlTitle">Kontrol Ekranı</p>
        <p className="controlSubTitle"> {data[id].title} </p>
      </CardHeader>
      <CardBody>{data[id].comp()}</CardBody>
    </Card>
  );
}

export default ControlComponent;
