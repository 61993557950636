import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
// import BlogOverview from "./views/BlogOverview";
// import UserProfileLite from "./views/UserProfileLite";
// import AddNewPost from "./views/AddNewPost";
// import Errors from "./views/Errors";
// import ComponentsOverview from "./views/ComponentsOverview";
// import Tables from "./views/Tables";
// import BlogPosts from "./views/BlogPosts";

import LoginScreen from "./views/Dashboard/login/LoginScreen";
import Home from "./views/Dashboard/home/Home";
import VideoCall from "./views/Dashboard/videocall/VideoCall";
import DetailCandidate from "./views/DetailCandidate/DetailCandidate";
import DetailCandidateInfo from "./views/DetailCandidate/DetailCandidateInfo";
import AdminHome from "./views/admin/AdminHome";
import AddOperator from "./views/admin/AddOperator/AddOperator";
import LoginScreenLayout from "./layouts/LoginScreenLayout";
import OperatorList from "./views/admin/OperatorList/OperatorList";
import OperatorDetail from "./views/admin/OperatorList/OperatorDetail";

import AdminCandidateDetail from "./views/admin/common/AdminCandidateDetail";

export default [
  {
    path: "/",
    exact: true,
    layout: LoginScreenLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/login",
    exact: true,
    layout: LoginScreenLayout,
    component: LoginScreen
  },
  {
    path: "/dashboard/home",
    layout: DefaultLayout,
    component: Home
  },
  {
    path: "/dashboard/candidate/detailCandidateInfo/:id",
    layout: DefaultLayout,
    component: DetailCandidateInfo
  },
  {
    path: "/dashboard/candidate/detail",
    exact: true,
    layout: DefaultLayout,
    component: DetailCandidate
  },
  {
    path: "/dashboard/videocall/:roomName",
    layout: DefaultLayout,
    component: VideoCall
  },
  {
    path: "/dashboard/admin/home",
    layout: DefaultLayout,
    component: AdminHome
  },
  {
    path: "/dashboard/admin/addOperator",
    layout: DefaultLayout,
    component: AddOperator
  },
  {
    path: "/dashboard/admin/operatorList",
    layout: DefaultLayout,
    component: OperatorList
  },
  {
    path: "/dashboard/admin/operatorDetail/:id",
    layout: DefaultLayout,
    component: OperatorDetail
  },
  {
    path: "/dashboard/admin/candidateDetail/:id",
    layout: DefaultLayout, 
    component: AdminCandidateDetail
  }
];
