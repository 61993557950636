import React from "react";
import DetailList from "./DetailList";
import { Row, Col } from "react-bootstrap";
import SummaryCards from "./SummaryCards";
import moment from "moment";
import DetailCandidateDropDownPart from "./DetailCandidateDropDownPart";

function SummaryInfo(props) {
  const list1 = [
    { listTitle: "İsim", listBody: props.form.name, listSeperator: ":" },
    { listTitle: "Soyisim", listBody: props.form.surname, listSeperator: ":" },
    {
      listTitle: "Doğum Tarihi",
      listBody: moment(props.form.birthDate).format("DD-MM-YYYY"),
      listSeperator: ":"
    },
    { listTitle: "T.C. Numarası", listBody: props.form.tc, listSeperator: ":" },
    {
      listTitle: "Telefon Numarası",
      listBody: props.form.phone,
      listSeperator: ":"
    },
    { listTitle: "", listBody: "" },
    { listTitle: "", listBody: "" },
    { listTitle: "", listBody: "" },
    { listTitle: "", listBody: "" }
  ];
  const list2 = [
    { listTitle: "İsim", listBody: props.info.name, listSeperator: ":" },
    { listTitle: "Soyisim", listBody: props.info.surname, listSeperator: ":" },
    {
      listTitle: "Doğum Tarihi",
      listBody: moment(props.info.birthDate).format("DD-MM-YYYY"),
      listSeperator: ":"
    },
    { listTitle: "T.C. Numarası", listBody: props.info.tc, listSeperator: ":" },
    {
      listTitle: "Seri Numarası",
      listBody: props.info.documentNumber,
      listSeperator: ":"
    },
    { listTitle: "Cinsiyet", listBody: props.info.gender, listSeperator: ":" },
    {
      listTitle: "Son Geçerlilik",
      listBody: moment(props.info.expiry_date).format("DD-MM-YYYY"),
      listSeperator: ":"
    }
  ];
  return (
    <DetailCandidateDropDownPart
      control={props.isControl}
      title={"Özet Bilgiler"}
      className={"m-2"}
    >
      <Row>
        <Col md={4}>
          <span className="spanTitle">
            Başvuru Numarası:{" "}
            <span className="cardSubtitle">{props.candidateInfo.id}</span>
          </span>
          <br />
          <span className="spanTitle">
            Başvuru Türü:{" "}
            <span className="cardSubtitle">
              {props.candidateInfo.applicationType}
            </span>
          </span>
        </Col>
        <Col md={{ span: 4, offset: 4 }}>
          <span className="spanTitle">
            Başvuru Tarihi:{" "}
            <span className="cardSubtitle">
              {moment(props.candidateInfo.applicationDate).format("DD-MM-YYYY")}
            </span>
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={2} style={{ paddingBlockEnd: "5%" }}>
          <Row>
            <SummaryCards
              title={"Müşteri Tipi"}
              subtitle={props.candidateInfo.applicant_type}
            ></SummaryCards>
          </Row>
          <br />
          <Row>
            <SummaryCards
              title={"Kimlik Türü"}
              subtitle={props.candidateInfo.idType}
            ></SummaryCards>
          </Row>
          <br />
          <Row>
            <SummaryCards
              title={"  KVKK İzni  "}
              subtitle={props.candidateInfo.gprd ? "Onaylandı" : "Onaylanmadı"}
            ></SummaryCards>
          </Row>
        </Col>
        <Col md={5} style={{ paddingBlockEnd: "5%" }}>
          <SummaryCards
            title={"Başvuru Formu Bilgileri"}
            subtitle={"Manuel Veri"}
          >
            <DetailList listItems={list1} />
          </SummaryCards>
        </Col>
        <Col md={5} style={{ paddingBlockEnd: "5%" }}>
          <SummaryCards
            title={"Kimlik OCR / MRZ Bilgileri"}
            subtitle={"Otomatik Veri"}
          >
            <DetailList listItems={list2} />
          </SummaryCards>
        </Col>
      </Row>
    </DetailCandidateDropDownPart>
  );
}

export default SummaryInfo;
