import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function ErrorModal(props) {
    return (
        <Modal {...props} onHide={props.handleClose} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.description}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      );
    }

export default ErrorModal;  