import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { isEqual } from "underscore";
import ApplicationStatus from "../../DetailCandidate/ApplicationStatus";
import ComponentsContainer from "../../ComponentsContainer";
import ApiService from "../../../services/ApiService";
import SummaryInfo from "../../DetailCandidate/SummaryInfo";
import DetailCandidateDropDownPart from "../../DetailCandidate/DetailCandidateDropDownPart";

export default function AdminCandidateDetail() {
  const token = localStorage.getItem("token");

  const [candidateInfo, setCandidateInfo] = useState({});
  const [info, setInfo] = useState({});
  const [form, setForm] = useState({});
  const [operator, setOperator] = useState({});

  let { id } = useParams();

  useEffect(() => {
    ApiService.get("/see_and_sign/banking/admin/get_candidate_detail", token, {
      applicationId: id
    }).then(({ data }) => {
      setCandidateInfo(data.candidate.info);
      setInfo(data.candidate.id);
      setForm(data.candidate.form);
      setOperator(data.candidate.operator);
      console.log(data);
    });
  }, []);

  console.log(operator);

  return (
    <ComponentsContainer title={"Doğrulama Raporu"}>
      <Row>
        <Col xs={12} md={8}>
          <DetailCandidateDropDownPart
            title={"Operatör Bilgileri"}
            className={"m-2"}
          >
            <Row>
              <Col md={4}>
                <span className="spanTitle">
                  Operatör ID:{" "}
                  <span className="cardSubtitle">{operator.id}</span>
                </span>
                <br />
                <span className="spanTitle">
                  Opertör Adı:{" "}
                  <span className="cardSubtitle">{operator.name}</span>
                </span>
                <br />
                <span className="spanTitle">
                  Opertör Soyadı:{" "}
                  <span className="cardSubtitle">{operator.surname}</span>
                </span>
              </Col>
            </Row>
          </DetailCandidateDropDownPart>
          <SummaryInfo form={form} candidateInfo={candidateInfo} info={info} />
        </Col>
        {/*SAĞ COL */}
        <Col xs={12} md={4}>
          <ApplicationStatus
            show={false}
            candidateInfo={candidateInfo}
            style={{
              backgroundColor: isEqual(candidateInfo.status, "Reddedildi")
                ? "red"
                : "green",
              textAlign: "center"
            }}
            status={candidateInfo.status}
          />
        </Col>
      </Row>
    </ComponentsContainer>
  );
}
